import React from 'react';
import DataGridList from "../../components/CommonList/DataGridList.js";

const Index = () => {

    const columns = [
        { name: 'Name', index: 'name', type: 'text' },
        { name: 'Enabled', index: 'is_active', type: 'input-switch' }
    ];

    const options = {
        crud: true,
        add: {
            route: "/admin/token/form"
        },
        edit: {
            route: "/admin/token/form"
        },
        details: {
            route: "/admin/token/details"
        },
        delete: {
            route: "token/"
        },
        enabledOpt: {
            url_edit: 'token/',
            field: 'is_active'
        }
    }

    const url = "tokens"

    return (
        <DataGridList url={url} columns={columns} options={options} />
    );
};

export default Index;
