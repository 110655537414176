let BACKEND_SERVER = null;
let BACKEND_SERVER_URL = null;
let KEY_GOOGLE_MAPS = null;
if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
    BACKEND_SERVER = "http://api-dev.resonic.natasquad.com/api/admin/";
}

const config = {
    basename: '',
    defaultPath: 'admin/dashboard',
    fontFamily: `'OpenSans', sans-serif`,
    borderRadius: 12,
    API_SERVER: BACKEND_SERVER,
    URL_SERVER: BACKEND_SERVER_URL,
    KEY_GOOGLE_MAPS: KEY_GOOGLE_MAPS,
    BASE_PATH_BACKEND: "http://api-dev.resonic.natasquad.com/",
};

export default config;
