import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function CloudPush() {
  return (
      <img src="https://media.istockphoto.com/id/1253437873/vector/yellow-warning-sign-work-in-progress-background.jpg?s=612x612&w=0&k=20&c=n4Mx6V6PNufYx6jTI-RzLsUHCjOLx-ypUxb5w7RKDuo="/>
  );
}

export default CloudPush;
