import React from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import DatePicker from "react-datepicker";

function Statistics() {
    return (
        <Container fluid>
            <Row>
                <Col lg="3" sm="6">
                    <Card className="card-stats">
                        <Card.Header>
                            <Card.Title as="h4">Date range</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row><Col>
                                <Form.Label>Range</Form.Label>
                                <div className="input-group mb-3">
                                    <Form.Select>
                                        <option>-- CUSTOM --</option>
                                        <option value="1">Last 3 months</option>
                                        <option value="2" selected>Last month</option>
                                        <option value="3">Last week</option>
                                    </Form.Select>
                                </div>
                            </Col></Row>
                            <Row>
                                <Col md="6">
                                    <Form.Label>From</Form.Label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend calendar-icon">
                                            <span className="input-group-text"><i className="fa fa-fw fa-calendar"></i></span>
                                        </div>
                                        <div className="datapickerContainer">
                                            <DatePicker
                                                selected={new Date(2023, 4, 1)}
                                                className="form-control"
                                                dateFormat="MM/dd/yyyy"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <Form.Label>To</Form.Label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend calendar-icon">
                                            <span className="input-group-text"><i className="fa fa-fw fa-calendar"></i></span>
                                        </div>
                                        <div className="datapickerContainer">
                                            <DatePicker
                                                selected={new Date(2023, 4, 31)}
                                                className="form-control"
                                                dateFormat="MM/dd/yyyy"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="9" sm="6">
                    <Card className="card-stats">
                        <Card.Header>
                            <Card.Title as="h4">Filters</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md="3" className="mb-3">
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Select>
                                        <option>-- ALL --</option>
                                        <option value="1">McDonald's</option>
                                        <option value="2">Uber Eats</option>
                                        <option value="3">Apple</option>
                                        <option value="4">Coca Cola</option>
                                    </Form.Select>
                                </Col>
                                <Col md="3" className="mb-3">
                                    <Form.Label>Channel</Form.Label>
                                    <Form.Select>
                                        <option>-- ALL --</option>
                                        <option value="1">Channel 1</option>
                                        <option value="2">Channel 2</option>
                                        <option value="3">Channel 3</option>
                                    </Form.Select>
                                </Col>
                                <Col md="3" className="mb-3">
                                    <Form.Label>Content</Form.Label>
                                    <Form.Select>
                                        <option>-- ALL --</option>
                                        <option value="1">Amazon - Apple Buds</option>
                                        <option value="2">ITV MCD</option>
                                        <option value="3">JB HiFi</option>
                                        <option value="4">JB HiFi</option>
                                        <option value="5">Survivor - within earshot</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" className="mb-3">
                                    <Form.Label>Search</Form.Label>
                                    <Form.Control type="text" placeholder="Enter search terms"/>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="3" sm="6">
                    <Card className="card-stats">
                        <Card.Header>
                            <h4 className="my-1">Total active tokens</h4>
                        </Card.Header>
                        <Card.Body>
                            <h1 className="mt-0">14</h1>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="3" sm="6">
                    <Card className="card-stats">
                        <Card.Header>
                            <h4 className="my-1">Total active campaigns</h4>
                        </Card.Header>
                        <Card.Body>
                            <h1 className="mt-0">63</h1>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="3" sm="6">
                    <Card className="card-stats">
                        <Card.Header>
                            <h4 className="my-1">Total content impressions</h4>
                        </Card.Header>
                        <Card.Body>
                            <h1 className="mt-0">145726</h1>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <Card>
                        <Card.Header>
                            <h4 className="my-1">By brand</h4>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th className="text-center">Active tokens</th>
                                    <th className="text-center">Campaigns</th>
                                    <th className="text-center">Impressions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>McDonald's</td>
                                    <td>8</td>
                                    <td>19</td>
                                    <td>31235</td>
                                </tr>
                                <tr>
                                    <td>Uber Eats</td>
                                    <td>9</td>
                                    <td>23</td>
                                    <td>32589</td>
                                </tr>
                                <tr>
                                    <td>Apple</td>
                                    <td>4</td>
                                    <td>8</td>
                                    <td>43749</td>
                                </tr>
                                <tr>
                                    <td>Coca Cola</td>
                                    <td>12</td>
                                    <td>21</td>
                                    <td>38153</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>


                </Col>
                <Col md="6">
                    <Card>
                        <Card.Header>
                            <h4 className="my-1">Impressions</h4>
                        </Card.Header>
                        <Card.Body>
                            <ChartistGraph
                                data={{
                                    labels: [
                                        "05/01",
                                        "",
                                        "",
                                        "05/15",
                                        "",
                                        "",
                                        "05/31",
                                    ],
                                    series: [
                                        [1052, 1127, 1266, 1486, 1032, 975, 1055],
                                        [1903, 1450, 1805, 1352, 1543, 855, 703],
                                        [1904, 1941, 1804, 1510, 2114, 1538, 1455],
                                        [2025, 1833, 1292, 1811, 903, 1286, 1908],
                                    ],
                                }}
                                type="Line"
                                options={{
                                    low: 0,
                                    // high: 800,
                                    showArea: false,
                                    height: "245px",
                                    axisX: {
                                        showGrid: false,
                                        labelInterpolationFnc: function (value) {
                                            return value;
                                        }
                                    },
                                    lineSmooth: true,
                                    showLine: true,
                                    showPoint: false,
                                    fullWidth: true,
                                    chartPadding: {
                                        right: 50,
                                    },
                                }}
                                responsiveOptions={[
                                    [
                                        "screen and (max-width: 640px)",
                                        {
                                            axisX: {
                                                labelInterpolationFnc: function (value) {
                                                    return value[0];
                                                },
                                            },
                                        },
                                    ],
                                ]}
                            />
                        </Card.Body>
                        <Card.Footer>
                            <div className="legend">
                                <i className="fa fa-circle" style={{color: "#1DC7EA"}}></i>McDonald's
                                <i className="fa fa-circle ml-2" style={{color: "#FB404B"}}></i>Uber Eats
                                <i className="fa fa-circle ml-2" style={{color: "#FFA534"}}></i>Apple
                                <i className="fa fa-circle ml-2" style={{color: "#9368E9"}}></i> Coca Cola
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md="6">

                </Col>
                <Col md="6">

                </Col>
            </Row>


            {/*<Row>*/}
            {/*    <Col md="8">*/}
            {/*        <Card>*/}
            {/*            <Card.Header>*/}
            {/*                <Card.Title as="h4">Users Behavior</Card.Title>*/}
            {/*                <p className="card-category">24 Hours performance</p>*/}
            {/*            </Card.Header>*/}
            {/*            <Card.Body>*/}
            {/*                <div className="ct-chart" id="chartHours">*/}
            {/*                    <ChartistGraph*/}
            {/*                        data={{*/}
            {/*                            labels: [*/}
            {/*                                "9:00AM",*/}
            {/*                                "12:00AM",*/}
            {/*                                "3:00PM",*/}
            {/*                                "6:00PM",*/}
            {/*                                "9:00PM",*/}
            {/*                                "12:00PM",*/}
            {/*                                "3:00AM",*/}
            {/*                                "6:00AM",*/}
            {/*                            ],*/}
            {/*                            series: [*/}
            {/*                                [287, 385, 490, 492, 554, 586, 698, 695],*/}
            {/*                                [67, 152, 143, 240, 287, 335, 435, 437],*/}
            {/*                                [23, 113, 67, 108, 190, 239, 307, 308],*/}
            {/*                            ],*/}
            {/*                        }}*/}
            {/*                        type="Line"*/}
            {/*                        options={{*/}
            {/*                            low: 0,*/}
            {/*                            high: 800,*/}
            {/*                            showArea: false,*/}
            {/*                            height: "245px",*/}
            {/*                            axisX: {*/}
            {/*                                showGrid: false,*/}
            {/*                            },*/}
            {/*                            lineSmooth: true,*/}
            {/*                            showLine: true,*/}
            {/*                            showPoint: true,*/}
            {/*                            fullWidth: true,*/}
            {/*                            chartPadding: {*/}
            {/*                                right: 50,*/}
            {/*                            },*/}
            {/*                        }}*/}
            {/*                        responsiveOptions={[*/}
            {/*                            [*/}
            {/*                                "screen and (max-width: 640px)",*/}
            {/*                                {*/}
            {/*                                    axisX: {*/}
            {/*                                        labelInterpolationFnc: function (value) {*/}
            {/*                                            return value[0];*/}
            {/*                                        },*/}
            {/*                                    },*/}
            {/*                                },*/}
            {/*                            ],*/}
            {/*                        ]}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </Card.Body>*/}
            {/*            <Card.Footer>*/}
            {/*                <div className="legend">*/}
            {/*                    <i className="fas fa-circle text-info"></i>*/}
            {/*                    Open <i className="fas fa-circle text-danger"></i>*/}
            {/*                    Click <i className="fas fa-circle text-warning"></i>*/}
            {/*                    Click Second Time*/}
            {/*                </div>*/}
            {/*                <hr></hr>*/}
            {/*                <div className="stats">*/}
            {/*                    <i className="fas fa-history"></i>*/}
            {/*                    Updated 3 minutes ago*/}
            {/*                </div>*/}
            {/*            </Card.Footer>*/}
            {/*        </Card>*/}
            {/*    </Col>*/}
            {/*    <Col md="4">*/}
            {/*        <Card>*/}
            {/*            <Card.Header>*/}
            {/*                <Card.Title as="h4">Email Statistics</Card.Title>*/}
            {/*                <p className="card-category">Last Campaign Performance</p>*/}
            {/*            </Card.Header>*/}
            {/*            <Card.Body>*/}
            {/*                <div*/}
            {/*                    className="ct-chart ct-perfect-fourth"*/}
            {/*                    id="chartPreferences"*/}
            {/*                >*/}
            {/*                    <ChartistGraph*/}
            {/*                        data={{*/}
            {/*                            labels: ["40%", "20%", "40%"],*/}
            {/*                            series: [40, 20, 40],*/}
            {/*                        }}*/}
            {/*                        type="Pie"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*                <div className="legend">*/}
            {/*                    <i className="fas fa-circle text-info"></i>*/}
            {/*                    Open <i className="fas fa-circle text-danger"></i>*/}
            {/*                    Bounce <i className="fas fa-circle text-warning"></i>*/}
            {/*                    Unsubscribe*/}
            {/*                </div>*/}
            {/*                <hr></hr>*/}
            {/*                <div className="stats">*/}
            {/*                    <i className="far fa-clock"></i>*/}
            {/*                    Campaign sent 2 days ago*/}
            {/*                </div>*/}
            {/*            </Card.Body>*/}
            {/*        </Card>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*    <Col md="6">*/}
            {/*        <Card>*/}
            {/*            <Card.Header>*/}
            {/*                <Card.Title as="h4">2017 Sales</Card.Title>*/}
            {/*                <p className="card-category">All products including Taxes</p>*/}
            {/*            </Card.Header>*/}
            {/*            <Card.Body>*/}
            {/*                <div className="ct-chart" id="chartActivity">*/}
            {/*                    <ChartistGraph*/}
            {/*                        data={{*/}
            {/*                            labels: [*/}
            {/*                                "Jan",*/}
            {/*                                "Feb",*/}
            {/*                                "Mar",*/}
            {/*                                "Apr",*/}
            {/*                                "Mai",*/}
            {/*                                "Jun",*/}
            {/*                                "Jul",*/}
            {/*                                "Aug",*/}
            {/*                                "Sep",*/}
            {/*                                "Oct",*/}
            {/*                                "Nov",*/}
            {/*                                "Dec",*/}
            {/*                            ],*/}
            {/*                            series: [*/}
            {/*                                [*/}
            {/*                                    542,*/}
            {/*                                    443,*/}
            {/*                                    320,*/}
            {/*                                    780,*/}
            {/*                                    553,*/}
            {/*                                    453,*/}
            {/*                                    326,*/}
            {/*                                    434,*/}
            {/*                                    568,*/}
            {/*                                    610,*/}
            {/*                                    756,*/}
            {/*                                    895,*/}
            {/*                                ],*/}
            {/*                                [*/}
            {/*                                    412,*/}
            {/*                                    243,*/}
            {/*                                    280,*/}
            {/*                                    580,*/}
            {/*                                    453,*/}
            {/*                                    353,*/}
            {/*                                    300,*/}
            {/*                                    364,*/}
            {/*                                    368,*/}
            {/*                                    410,*/}
            {/*                                    636,*/}
            {/*                                    695,*/}
            {/*                                ],*/}
            {/*                            ],*/}
            {/*                        }}*/}
            {/*                        type="Bar"*/}
            {/*                        options={{*/}
            {/*                            seriesBarDistance: 10,*/}
            {/*                            axisX: {*/}
            {/*                                showGrid: false,*/}
            {/*                            },*/}
            {/*                            height: "245px",*/}
            {/*                        }}*/}
            {/*                        responsiveOptions={[*/}
            {/*                            [*/}
            {/*                                "screen and (max-width: 640px)",*/}
            {/*                                {*/}
            {/*                                    seriesBarDistance: 5,*/}
            {/*                                    axisX: {*/}
            {/*                                        labelInterpolationFnc: function (value) {*/}
            {/*                                            return value[0];*/}
            {/*                                        },*/}
            {/*                                    },*/}
            {/*                                },*/}
            {/*                            ],*/}
            {/*                        ]}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </Card.Body>*/}
            {/*            <Card.Footer>*/}
            {/*                <div className="legend">*/}
            {/*                    <i className="fas fa-circle text-info"></i>*/}
            {/*                    Tesla Model S <i className="fas fa-circle text-danger"></i>*/}
            {/*                    BMW 5 Series*/}
            {/*                </div>*/}
            {/*                <hr></hr>*/}
            {/*                <div className="stats">*/}
            {/*                    <i className="fas fa-check"></i>*/}
            {/*                    Data information certified*/}
            {/*                </div>*/}
            {/*            </Card.Footer>*/}
            {/*        </Card>*/}
            {/*    </Col>*/}
            {/*    <Col md="6">*/}
            {/*        <Card className="card-tasks">*/}
            {/*            <Card.Header>*/}
            {/*                <Card.Title as="h4">Tasks</Card.Title>*/}
            {/*                <p className="card-category">Backend development</p>*/}
            {/*            </Card.Header>*/}
            {/*            <Card.Body>*/}
            {/*                <div className="table-full-width">*/}
            {/*                    <Table>*/}
            {/*                        <tbody>*/}
            {/*                        <tr>*/}
            {/*                            <td>*/}
            {/*                                <Form.Check className="mb-1 pl-0">*/}
            {/*                                    <Form.Check.Label>*/}
            {/*                                        <Form.Check.Input*/}
            {/*                                            defaultValue=""*/}
            {/*                                            type="checkbox"*/}
            {/*                                        ></Form.Check.Input>*/}
            {/*                                        <span className="form-check-sign"></span>*/}
            {/*                                    </Form.Check.Label>*/}
            {/*                                </Form.Check>*/}
            {/*                            </td>*/}
            {/*                            <td>*/}
            {/*                                Sign contract for "What are conference organizers*/}
            {/*                                afraid of?"*/}
            {/*                            </td>*/}
            {/*                            <td className="td-actions text-right">*/}
            {/*                                <OverlayTrigger*/}
            {/*                                    overlay={*/}
            {/*                                        <Tooltip id="tooltip-488980961">*/}
            {/*                                            Edit Task..*/}
            {/*                                        </Tooltip>*/}
            {/*                                    }*/}
            {/*                                >*/}
            {/*                                    <Button*/}
            {/*                                        className="btn-simple btn-link p-1"*/}
            {/*                                        type="button"*/}
            {/*                                        variant="info"*/}
            {/*                                    >*/}
            {/*                                        <i className="fas fa-edit"></i>*/}
            {/*                                    </Button>*/}
            {/*                                </OverlayTrigger>*/}
            {/*                                <OverlayTrigger*/}
            {/*                                    overlay={*/}
            {/*                                        <Tooltip id="tooltip-506045838">Remove..</Tooltip>*/}
            {/*                                    }*/}
            {/*                                >*/}
            {/*                                    <Button*/}
            {/*                                        className="btn-simple btn-link p-1"*/}
            {/*                                        type="button"*/}
            {/*                                        variant="danger"*/}
            {/*                                    >*/}
            {/*                                        <i className="fas fa-times"></i>*/}
            {/*                                    </Button>*/}
            {/*                                </OverlayTrigger>*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                        <tr>*/}
            {/*                            <td>*/}
            {/*                                <Form.Check className="mb-1 pl-0">*/}
            {/*                                    <Form.Check.Label>*/}
            {/*                                        <Form.Check.Input*/}
            {/*                                            defaultChecked*/}
            {/*                                            defaultValue=""*/}
            {/*                                            type="checkbox"*/}
            {/*                                        ></Form.Check.Input>*/}
            {/*                                        <span className="form-check-sign"></span>*/}
            {/*                                    </Form.Check.Label>*/}
            {/*                                </Form.Check>*/}
            {/*                            </td>*/}
            {/*                            <td>*/}
            {/*                                Lines From Great Russian Literature? Or E-mails From*/}
            {/*                                My Boss?*/}
            {/*                            </td>*/}
            {/*                            <td className="td-actions text-right">*/}
            {/*                                <OverlayTrigger*/}
            {/*                                    overlay={*/}
            {/*                                        <Tooltip id="tooltip-537440761">*/}
            {/*                                            Edit Task..*/}
            {/*                                        </Tooltip>*/}
            {/*                                    }*/}
            {/*                                >*/}
            {/*                                    <Button*/}
            {/*                                        className="btn-simple btn-link p-1"*/}
            {/*                                        type="button"*/}
            {/*                                        variant="info"*/}
            {/*                                    >*/}
            {/*                                        <i className="fas fa-edit"></i>*/}
            {/*                                    </Button>*/}
            {/*                                </OverlayTrigger>*/}
            {/*                                <OverlayTrigger*/}
            {/*                                    overlay={*/}
            {/*                                        <Tooltip id="tooltip-21130535">Remove..</Tooltip>*/}
            {/*                                    }*/}
            {/*                                >*/}
            {/*                                    <Button*/}
            {/*                                        className="btn-simple btn-link p-1"*/}
            {/*                                        type="button"*/}
            {/*                                        variant="danger"*/}
            {/*                                    >*/}
            {/*                                        <i className="fas fa-times"></i>*/}
            {/*                                    </Button>*/}
            {/*                                </OverlayTrigger>*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                        <tr>*/}
            {/*                            <td>*/}
            {/*                                <Form.Check className="mb-1 pl-0">*/}
            {/*                                    <Form.Check.Label>*/}
            {/*                                        <Form.Check.Input*/}
            {/*                                            defaultChecked*/}
            {/*                                            defaultValue=""*/}
            {/*                                            type="checkbox"*/}
            {/*                                        ></Form.Check.Input>*/}
            {/*                                        <span className="form-check-sign"></span>*/}
            {/*                                    </Form.Check.Label>*/}
            {/*                                </Form.Check>*/}
            {/*                            </td>*/}
            {/*                            <td>*/}
            {/*                                Flooded: One year later, assessing what was lost and*/}
            {/*                                what was found when a ravaging rain swept through*/}
            {/*                                metro Detroit*/}
            {/*                            </td>*/}
            {/*                            <td className="td-actions text-right">*/}
            {/*                                <OverlayTrigger*/}
            {/*                                    overlay={*/}
            {/*                                        <Tooltip id="tooltip-577232198">*/}
            {/*                                            Edit Task..*/}
            {/*                                        </Tooltip>*/}
            {/*                                    }*/}
            {/*                                >*/}
            {/*                                    <Button*/}
            {/*                                        className="btn-simple btn-link p-1"*/}
            {/*                                        type="button"*/}
            {/*                                        variant="info"*/}
            {/*                                    >*/}
            {/*                                        <i className="fas fa-edit"></i>*/}
            {/*                                    </Button>*/}
            {/*                                </OverlayTrigger>*/}
            {/*                                <OverlayTrigger*/}
            {/*                                    overlay={*/}
            {/*                                        <Tooltip id="tooltip-773861645">Remove..</Tooltip>*/}
            {/*                                    }*/}
            {/*                                >*/}
            {/*                                    <Button*/}
            {/*                                        className="btn-simple btn-link p-1"*/}
            {/*                                        type="button"*/}
            {/*                                        variant="danger"*/}
            {/*                                    >*/}
            {/*                                        <i className="fas fa-times"></i>*/}
            {/*                                    </Button>*/}
            {/*                                </OverlayTrigger>*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                        <tr>*/}
            {/*                            <td>*/}
            {/*                                <Form.Check className="mb-1 pl-0">*/}
            {/*                                    <Form.Check.Label>*/}
            {/*                                        <Form.Check.Input*/}
            {/*                                            defaultChecked*/}
            {/*                                            type="checkbox"*/}
            {/*                                        ></Form.Check.Input>*/}
            {/*                                        <span className="form-check-sign"></span>*/}
            {/*                                    </Form.Check.Label>*/}
            {/*                                </Form.Check>*/}
            {/*                            </td>*/}
            {/*                            <td>*/}
            {/*                                Create 4 Invisible User Experiences you Never Knew*/}
            {/*                                About*/}
            {/*                            </td>*/}
            {/*                            <td className="td-actions text-right">*/}
            {/*                                <OverlayTrigger*/}
            {/*                                    overlay={*/}
            {/*                                        <Tooltip id="tooltip-422471719">*/}
            {/*                                            Edit Task..*/}
            {/*                                        </Tooltip>*/}
            {/*                                    }*/}
            {/*                                >*/}
            {/*                                    <Button*/}
            {/*                                        className="btn-simple btn-link p-1"*/}
            {/*                                        type="button"*/}
            {/*                                        variant="info"*/}
            {/*                                    >*/}
            {/*                                        <i className="fas fa-edit"></i>*/}
            {/*                                    </Button>*/}
            {/*                                </OverlayTrigger>*/}
            {/*                                <OverlayTrigger*/}
            {/*                                    overlay={*/}
            {/*                                        <Tooltip id="tooltip-829164576">Remove..</Tooltip>*/}
            {/*                                    }*/}
            {/*                                >*/}
            {/*                                    <Button*/}
            {/*                                        className="btn-simple btn-link p-1"*/}
            {/*                                        type="button"*/}
            {/*                                        variant="danger"*/}
            {/*                                    >*/}
            {/*                                        <i className="fas fa-times"></i>*/}
            {/*                                    </Button>*/}
            {/*                                </OverlayTrigger>*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                        <tr>*/}
            {/*                            <td>*/}
            {/*                                <Form.Check className="mb-1 pl-0">*/}
            {/*                                    <Form.Check.Label>*/}
            {/*                                        <Form.Check.Input*/}
            {/*                                            defaultValue=""*/}
            {/*                                            type="checkbox"*/}
            {/*                                        ></Form.Check.Input>*/}
            {/*                                        <span className="form-check-sign"></span>*/}
            {/*                                    </Form.Check.Label>*/}
            {/*                                </Form.Check>*/}
            {/*                            </td>*/}
            {/*                            <td>Read "Following makes Medium better"</td>*/}
            {/*                            <td className="td-actions text-right">*/}
            {/*                                <OverlayTrigger*/}
            {/*                                    overlay={*/}
            {/*                                        <Tooltip id="tooltip-160575228">*/}
            {/*                                            Edit Task..*/}
            {/*                                        </Tooltip>*/}
            {/*                                    }*/}
            {/*                                >*/}
            {/*                                    <Button*/}
            {/*                                        className="btn-simple btn-link p-1"*/}
            {/*                                        type="button"*/}
            {/*                                        variant="info"*/}
            {/*                                    >*/}
            {/*                                        <i className="fas fa-edit"></i>*/}
            {/*                                    </Button>*/}
            {/*                                </OverlayTrigger>*/}
            {/*                                <OverlayTrigger*/}
            {/*                                    overlay={*/}
            {/*                                        <Tooltip id="tooltip-922981635">Remove..</Tooltip>*/}
            {/*                                    }*/}
            {/*                                >*/}
            {/*                                    <Button*/}
            {/*                                        className="btn-simple btn-link p-1"*/}
            {/*                                        type="button"*/}
            {/*                                        variant="danger"*/}
            {/*                                    >*/}
            {/*                                        <i className="fas fa-times"></i>*/}
            {/*                                    </Button>*/}
            {/*                                </OverlayTrigger>*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                        <tr>*/}
            {/*                            <td>*/}
            {/*                                <Form.Check className="mb-1 pl-0">*/}
            {/*                                    <Form.Check.Label>*/}
            {/*                                        <Form.Check.Input*/}
            {/*                                            defaultValue=""*/}
            {/*                                            disabled*/}
            {/*                                            type="checkbox"*/}
            {/*                                        ></Form.Check.Input>*/}
            {/*                                        <span className="form-check-sign"></span>*/}
            {/*                                    </Form.Check.Label>*/}
            {/*                                </Form.Check>*/}
            {/*                            </td>*/}
            {/*                            <td>Unfollow 5 enemies from twitter</td>*/}
            {/*                            <td className="td-actions text-right">*/}
            {/*                                <OverlayTrigger*/}
            {/*                                    overlay={*/}
            {/*                                        <Tooltip id="tooltip-938342127">*/}
            {/*                                            Edit Task..*/}
            {/*                                        </Tooltip>*/}
            {/*                                    }*/}
            {/*                                >*/}
            {/*                                    <Button*/}
            {/*                                        className="btn-simple btn-link p-1"*/}
            {/*                                        type="button"*/}
            {/*                                        variant="info"*/}
            {/*                                    >*/}
            {/*                                        <i className="fas fa-edit"></i>*/}
            {/*                                    </Button>*/}
            {/*                                </OverlayTrigger>*/}
            {/*                                <OverlayTrigger*/}
            {/*                                    overlay={*/}
            {/*                                        <Tooltip id="tooltip-119603706">Remove..</Tooltip>*/}
            {/*                                    }*/}
            {/*                                >*/}
            {/*                                    <Button*/}
            {/*                                        className="btn-simple btn-link p-1"*/}
            {/*                                        type="button"*/}
            {/*                                        variant="danger"*/}
            {/*                                    >*/}
            {/*                                        <i className="fas fa-times"></i>*/}
            {/*                                    </Button>*/}
            {/*                                </OverlayTrigger>*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                        </tbody>*/}
            {/*                    </Table>*/}
            {/*                </div>*/}
            {/*            </Card.Body>*/}
            {/*            <Card.Footer>*/}
            {/*                <hr></hr>*/}
            {/*                <div className="stats">*/}
            {/*                    <i className="now-ui-icons loader_refresh spin"></i>*/}
            {/*                    Updated 3 minutes ago*/}
            {/*                </div>*/}
            {/*            </Card.Footer>*/}
            {/*        </Card>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </Container>
    );
}

export default Statistics;
