import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";

import routes from "routes.js";

function Header() {
  const location = useLocation();
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const logOut = () => {
      window.localStorage.removeItem('userLoggin');
  }

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2 d-lg-none btn-fill d-flex justify-content-center align-items-center mobile-menu p-12-10">
              <i className="fa fa-fw fa-align-justify"></i>
          </Navbar.Toggle>
          <Navbar.Brand
            id="title-section-id"
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
            {getBrandText()}
          </Navbar.Brand>
        </div>
        <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center mobile-menu p-12-10"
            onClick={mobileSidebarToggle}
        >
            <i className="fa fa-fw fa-angle-left"></i>
        </Button>
        <Navbar.Collapse id="basic-navbar-nav">
          {/*<Nav className="nav mr-auto" navbar>*/}
            {/*<Nav.Item>*/}
              {/*<Nav.Link*/}
                {/*data-toggle="dropdown"*/}
                {/*href="#pablo"*/}
                {/*onClick={(e) => e.preventDefault()}*/}
                {/*className="m-0"*/}
              {/*>*/}
                {/*<i className="nc-icon nc-palette"></i>*/}
                {/*<span className="d-lg-none ml-1">Dashboard</span>*/}
              {/*</Nav.Link>*/}
            {/*</Nav.Item>*/}
            {/*<Dropdown as={Nav.Item}>*/}
              {/*<Dropdown.Toggle*/}
                {/*as={Nav.Link}*/}
                {/*data-toggle="dropdown"*/}
                {/*id="dropdown-67443507"*/}
                {/*variant="default"*/}
                {/*className="m-0"*/}
              {/*>*/}
                {/*<i className="nc-icon nc-planet"></i>*/}
                {/*<span className="notification">5</span>*/}
                {/*<span className="d-lg-none ml-1">Notification</span>*/}
              {/*</Dropdown.Toggle>*/}
              {/*<Dropdown.Menu>*/}
                {/*<Dropdown.Item*/}
                  {/*href="#pablo"*/}
                  {/*onClick={(e) => e.preventDefault()}*/}
                {/*>*/}
                  {/*Notification 1*/}
                {/*</Dropdown.Item>*/}
                {/*<Dropdown.Item*/}
                  {/*href="#pablo"*/}
                  {/*onClick={(e) => e.preventDefault()}*/}
                {/*>*/}
                  {/*Notification 2*/}
                {/*</Dropdown.Item>*/}
                {/*<Dropdown.Item*/}
                  {/*href="#pablo"*/}
                  {/*onClick={(e) => e.preventDefault()}*/}
                {/*>*/}
                  {/*Notification 3*/}
                {/*</Dropdown.Item>*/}
                {/*<Dropdown.Item*/}
                  {/*href="#pablo"*/}
                  {/*onClick={(e) => e.preventDefault()}*/}
                {/*>*/}
                  {/*Notification 4*/}
                {/*</Dropdown.Item>*/}
                {/*<Dropdown.Item*/}
                  {/*href="#pablo"*/}
                  {/*onClick={(e) => e.preventDefault()}*/}
                {/*>*/}
                  {/*Another notification*/}
                {/*</Dropdown.Item>*/}
              {/*</Dropdown.Menu>*/}
            {/*</Dropdown>*/}
            {/*<Nav.Item>*/}
              {/*<Nav.Link*/}
                {/*className="m-0"*/}
                {/*href="#pablo"*/}
                {/*onClick={(e) => e.preventDefault()}*/}
              {/*>*/}
                {/*<i className="nc-icon nc-zoom-split"></i>*/}
                {/*<span className="d-lg-block"> Search</span>*/}
              {/*</Nav.Link>*/}
            {/*</Nav.Item>*/}
          {/*</Nav>*/}
          <Nav className="ml-auto" navbar>
            <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-icon">My account</span>
              </Nav.Link>
            </Nav.Item>
            {/*<Dropdown as={Nav.Item}>*/}
            {/*  <Dropdown.Toggle*/}
            {/*    aria-expanded={false}*/}
            {/*    aria-haspopup={true}*/}
            {/*    as={Nav.Link}*/}
            {/*    data-toggle="dropdown"*/}
            {/*    id="navbarDropdownMenuLink"*/}
            {/*    variant="default"*/}
            {/*    className="m-0"*/}
            {/*  >*/}
            {/*    <span className="no-icon">Dropdown</span>*/}
            {/*  </Dropdown.Toggle>*/}
            {/*  <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">*/}
            {/*    <Dropdown.Item*/}
            {/*      href="#pablo"*/}
            {/*      onClick={(e) => e.preventDefault()}*/}
            {/*    >*/}
            {/*      Action*/}
            {/*    </Dropdown.Item>*/}
            {/*    <Dropdown.Item*/}
            {/*      href="#pablo"*/}
            {/*      onClick={(e) => e.preventDefault()}*/}
            {/*    >*/}
            {/*      Another action*/}
            {/*    </Dropdown.Item>*/}
            {/*    <Dropdown.Item*/}
            {/*      href="#pablo"*/}
            {/*      onClick={(e) => e.preventDefault()}*/}
            {/*    >*/}
            {/*      Something*/}
            {/*    </Dropdown.Item>*/}
            {/*    <Dropdown.Item*/}
            {/*      href="#pablo"*/}
            {/*      onClick={(e) => e.preventDefault()}*/}
            {/*    >*/}
            {/*      Something else here*/}
            {/*    </Dropdown.Item>*/}
            {/*    <div className="divider"></div>*/}
            {/*    <Dropdown.Item*/}
            {/*      href="#pablo"*/}
            {/*      onClick={(e) => e.preventDefault()}*/}
            {/*    >*/}
            {/*      Separated link*/}
            {/*    </Dropdown.Item>*/}
            {/*  </Dropdown.Menu>*/}
            {/*</Dropdown>*/}
            <Nav.Item>
              <Nav.Link
                className="m-0"
                href="/"
                onClick={logOut}              >
                  <i className="nc-icon nc-button-power pr-2"></i> Log out
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
