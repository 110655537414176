import React from 'react';
import DataGridList from "../../components/CommonList/DataGridList.js";
import ScheduleCalendarForm from "./calendar";

const Index = () => {
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));

    const columns = [
        { name: 'Token', index: 'token_name', type: 'text' },
        { name: 'Content', index: 'content_name', type: 'text' },
        { name: 'Start Date', index: 'start_date', type: 'date' },
        { name: 'End Date', index: 'end_date', type: 'date' },
        { name: 'Enabled', index: 'is_active', type: 'input-switch' }
    ];

    const options = {
        crud: true,
        add: {
            route: "/admin/schedule/form"
        },
        edit: {
            route: "/admin/schedule/form"
        },
        details: {
            route: "/admin/schedule/details"
        },
        delete: {
            route: "schedule/"
        },
        enabledOpt: {
            url_edit: 'schedule/',
            field: 'is_active'
        }
    }

    const url = "schedules"

    return (
        account.media_provider_type == 'BROADCAST' ?
            <ScheduleCalendarForm /> :
        <DataGridList url={url} columns={columns} options={options} />
    );
};

export default Index;
