import React from 'react';
import DataGridList from "../../components/CommonList/DataGridList.js";

const Index = () => {
    const columns = [
        { name: 'Name', index: 'name', type: 'text' },
        { name: 'Image', index: 'image', type: 'image' },
        { name: 'Thumbnail', index: 'thumbnail', type: 'image' },
        { name: 'Url', index: 'url', type: 'text' },
        { name: 'Enabled', index: 'is_active', type: 'input-switch' }
    ];

    const options = {
        crud: true,
        formdata: true,
        add: {
            route: "/admin/content/form"
        },
        edit: {
            route: "/admin/content/form"
        },
        details: {
            route: "/admin/content/details"
        },
        delete: {
            route: "content/"
        },
        enabledOpt: {
            url_edit: 'content/',
            field: 'is_active'
        }
    }

    const url = "contents"

    return (
        <DataGridList url={url} columns={columns} options={options} />
    );
};

export default Index;
