import React from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

function Dashboard() {
  return (
    // <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Header>
                <h4 className="my-1">Active Tokens</h4>
              </Card.Header>
              <Card.Body>
                <h1 className="mt-0">7</h1>
              </Card.Body>
              <Card.Footer>
                <div className="stats">
                  <i className="fas fa-redo mr-1"></i>
                  Last week
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Header>
                <h4 className="my-1">Active Campaigns</h4>
              </Card.Header>
              <Card.Body>
                <h1 className="mt-0">23</h1>
              </Card.Body>
              <Card.Footer>
                <div className="stats">
                  <i className="fas fa-redo mr-1"></i>
                  Last week
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Header>
                <h4 className="my-1">Content Impressions</h4>
              </Card.Header>
              <Card.Body>
                <h1 className="mt-0">34560</h1>
              </Card.Body>
              <Card.Footer>
                <div className="stats">
                  <i className="fas fa-redo mr-1"></i>
                  Last week
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Top brands</Card.Title>
                <p className="card-category">Last week performance</p>
              </Card.Header>
              <Card.Body>
                <div>
                  <ChartistGraph
                    data={{
                      labels: ["20%", "30%", "10%", "40%"],
                      series: [20, 30, 10, 40],
                    }}
                    type="Pie"
                  />
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fa fa-circle" style={{color: "#1DC7EA"}}></i>McDonald's
                  <i className="fa fa-circle ml-2" style={{color: "#FB404B"}}></i>Uber Eats
                  <i className="fa fa-circle ml-2" style={{color: "#FFA534"}}></i>Apple
                  <i className="fa fa-circle ml-2" style={{color: "#9368E9"}}></i>Coca Cola
                </div>
                </Card.Footer>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Content impressions</Card.Title>
                <p className="card-category">Last week performance</p>
              </Card.Header>
              <Card.Body>
                <div>
                  <ChartistGraph
                    data={{
                      labels: [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday",
                      ],
                      series: [
                        [671, 427, 566, 786, 332, 575, 755],
                        [903, 450, 805, 352, 543, 655, 803],
                        [404, 341, 604, 210, 314, 238, 255],
                        [1025, 833, 892, 1211, 403, 786, 1108],
                      ],
                    }}
                    type="Bar"
                    options={{
                      low: 0,
                      // high: 800,
                      showArea: false,
                      height: "245px",
                      axisX: {
                        showGrid: false,
                      },
                      lineSmooth: true,
                      showLine: true,
                      showPoint: true,
                      fullWidth: true,
                      chartPadding: {
                        right: 50,
                      },
                    }}
                    responsiveOptions={[
                      [
                        "screen and (max-width: 640px)",
                        {
                          axisX: {
                            labelInterpolationFnc: function (value) {
                              return value[0];
                            },
                          },
                        },
                      ],
                    ]}
                  />
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fa fa-circle" style={{color: "#1DC7EA"}}></i>McDonald's
                  <i className="fa fa-circle ml-2" style={{color: "#FB404B"}}></i>Uber Eats
                  <i className="fa fa-circle ml-2" style={{color: "#FFA534"}}></i>Apple
                  <i className="fa fa-circle ml-2" style={{color: "#9368E9"}}></i> Coca Cola
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>

          <Col md="4">
          </Col>
        </Row>
      </Container>
    // </>
  );
}

export default Dashboard;
