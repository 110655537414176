import React, { useEffect, useState, SyntheticEvent } from 'react';
import {NavLink, useHistory} from "react-router-dom";
import {Form, Button} from 'react-bootstrap';
import configData from '../../config';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useRef } from 'react';

const ContentForm = (props) => {

    const [Name, setName] = useState('');
    const [Image, setImage] = useState(null);
    const [Thumbnail, setThumbnail] = useState(null);
    const [Url, setUrl] = useState('');
    const [Enabled, setEnabled] = useState(true);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(props?.location?.aboutProps?.id);
    const [loadingData, setLoadingData] = useState(false);
    const history = useHistory();

    const [PreviewImage, setPreviewImage] = useState();
    const [PreviewThumb, setPreviewThumb] = useState();
    const [ShowRemoveImage, setShowRemoveImage] = useState(false);
    const [ShowRemoveThumb, setShowRemoveThumb] = useState(false);
    const [heightPicture, setHeightPicture] = useState(0);
    const aRef = useRef(null);

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "content/" + id ,
                    { headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            var result = response.data;
                            setName(result.name);
                            setUrl(result.url);
                            setEnabled(result.is_active);

                            if (result.image != "" && result.image != undefined && result.image != null && result.image != 'null') {
                                setPreviewImage(configData.BASE_PATH_BACKEND + result.image);
                                setShowRemoveImage(true);
                            }
                            if (result.thumbnail != "" && result.thumbnail != undefined && result.thumbnail != null && result.thumbnail != 'null') {
                                setPreviewThumb(configData.BASE_PATH_BACKEND + result.thumbnail);
                                setShowRemoveThumb(true);
                            }
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        //Swal.fire({
                        //    title: 'Erreur!',
                        //    text: "Une erreur s'est produite",
                        //    icon: 'error',
                        //    confirmButtonText: 'OK'
                        //});
                        setLoadingData(false);
                    });
            } catch (err) {
                //Swal.fire({
                //    title: 'Erreur!',
                //    text: "Une erreur s'est produite",
                //    icon: 'error',
                //    confirmButtonText: 'OK'
                //});
                setLoadingData(false);
            }
        }
    }

    const saveData = async (e) => {
        e.preventDefault();
        var error = false;
        const formData = new FormData(e.currentTarget);
        if(Image == null){
            if (id > 0)
                formData.delete('image')
            else{
                document.getElementById('error-image').classList.remove('d-none');
                error = true;
            }
        }else{
            document.getElementById('error-image').classList.add('d-none');
        }


        if(Thumbnail == null)
        {
            if (id > 0)
                formData.delete('thumbnail')
            else{
                document.getElementById('error-thumbnail').classList.remove('d-none');
                error = true;
            }
        }else{
            document.getElementById('error-thumbnail').classList.add('d-none');
        }

        console.log(error)

        if(!error){
            setLoadingData(true);
            formData.append('is_active',Enabled);
            try {
                if (id > 0) {
                    axios.put(configData.API_SERVER + 'content/' + id,
                        formData,
                        {
                            headers: {
                                "Authorization" : `Bearer ${account.token}`,
                                "Content-Type": "multipart/form-data"
                            }
                        })
                        .then(function (response) {
                            if (response.status == 200 || response.status == 204) {
                                Swal.fire({
                                    title: 'Success !',
                                    text: 'Successful Operation',
                                    icon: 'success',
                                    confirmButtonText: 'OK'
                                })
                                return history.push('/admin/contents');
                            }
                        })
                        .catch(function (error) {
                            setLoadingData(false);
                        });
                }else{
                    axios.post(configData.API_SERVER + 'content' ,
                        formData,
                        {
                            headers: {
                                "Authorization" : `Bearer ${account.token}`,
                                "Content-Type": "multipart/form-data"
                            }
                        })
                        .then(function (response) {
                            if (response.status == 200 || response.status == 204) {
                                Swal.fire({
                                    title: 'Success !',
                                    text: 'Successful Operation',
                                    icon: 'success',
                                    confirmButtonText: 'OK'
                                })
                                return history.push('/admin/contents');
                            }
                        })
                        .catch(function (error) {
                            setLoadingData(false);
                        });
                }
            } catch (err) {
                setLoadingData(false);
            }
        }

    }

    const selectImage = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setImage(file)
            setPreviewImage(URL.createObjectURL(e.target.files[0]));
            setShowRemoveImage(true);
        }
    }

    const deleteImage = async (e) => {
        setPreviewImage();
        aRef.current.value = null;
        setShowRemoveImage(false);
    }

    const selectThumb = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setThumbnail(file)
            setPreviewThumb(URL.createObjectURL(e.target.files[0]));
            setShowRemoveThumb(true);
        }
    }

    const deleteThumb = async (e) => {
        setPreviewThumb();
        aRef.current.value = null;
        setShowRemoveThumb(false);
    }

    const getListSize = () => {
        let item = document.querySelector('.user-picture');
        if(item != null)
            setHeightPicture(item.offsetWidth);
    };

    useEffect(() => {
        getById();
        window.addEventListener("resize", getListSize);
        var selector = document.getElementById('title-section-id');
        if(selector != undefined){
            if(id > 0)
                selector.textContent = "Edit Content";
            else
                selector.textContent = "Create Content";
        }
        getListSize();
    }, []);

    return (
        loadingData ?
            <div className="preload"><div className="preloader-spinner"></div></div>
            :
            <Form className="create-form" onSubmit={saveData} autoComplete="off">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control name="name" type="text" required placeholder='Name' value={Name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Url</Form.Label>
                                    <Form.Control name="url" type="text" placeholder='Url' value={Url} onChange={(e) => setUrl(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Image</Form.Label>
                                    {/*<div className="close-picture-container">*/}
                                        {/*{*/}
                                            {/*(ShowRemoveImage) ? <button type="button" className="close btn btn-sm btn-danger" onClick={deleteImage}><i className="nc-icon nc-simple-remove"></i></button> : ""*/}
                                        {/*}*/}
                                    {/*</div>*/}

                                    <label className="picture user-picture" htmlFor="picture__input-image" tabIndex="0" style={{height: heightPicture}}>
                                        {
                                            (ShowRemoveImage) ? <img className="picture__image" src={PreviewImage} alt="" /> : <span>Select an image</span>
                                        }
                                    </label>

                                    <input type="file" ref={aRef} name="image" id="picture__input-image" class="picture__input" accept="image/png" onChange={selectImage} />
                                    <span id="error-image" className="text-danger d-none"><i className="fas fa-circle fa-xs"></i>Required field</span>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Thumbnail</Form.Label>
                                    {/*<div className="close-picture-container">*/}
                                        {/*{*/}
                                            {/*(ShowRemoveThumb) ? <button type="button" className="close btn btn-sm btn-danger" onClick={deleteThumb}><i className="nc-icon nc-simple-remove"></i></button> : ""*/}
                                        {/*}*/}
                                    {/*</div>*/}

                                    <label className="picture user-picture" htmlFor="picture__input-thumb" tabIndex="0" style={{height: heightPicture}}>
                                        {
                                            (ShowRemoveThumb) ? <img className="picture__image" src={PreviewThumb} alt="" /> : <span>Select a thumbnail</span>
                                        }
                                    </label>

                                    <input type="file" ref={aRef} name="thumbnail" id="picture__input-thumb" class="picture__input" accept="image/png" onChange={selectThumb} />
                                    <span id="error-thumbnail" className="text-danger d-none"><i className="fas fa-circle fa-xs"></i>Required field</span>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Enabled</Form.Label>
                                    <Form.Check name="Enabled" type="checkbox" checked={Enabled} onChange={(e) => setEnabled(!Enabled)} />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-12 footer-form">
                                <NavLink className="btn btn-secondary" to="/admin/contents"><i className="fa fa-fw fa-times"></i> Cancel</NavLink>
                                <Button className="btn btn-primary" type='submit'><i className="fa fa-fw fa-floppy-o"></i> Save</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
    );
};

export default ContentForm;
