import React, { useEffect, useState, SyntheticEvent } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {Form, Button} from 'react-bootstrap';
import configData from '../../config';
import axios from 'axios';
import Swal from 'sweetalert2';

const TokenForm = (props) => {
    const [Name, setName] = useState('');
    const [Type, setType] = useState('');
    const [Group, setGroup] = useState('');
    const [Schedule, setSchedule] = useState('');
    const [Enabled, setEnabled] = useState(true);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(props?.location?.aboutProps?.id);
    const [loadingData, setLoadingData] = useState(false);
    const history = useHistory();

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "token/" + id ,
                    { headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            var result = response.data;
                            setName(result.name);
                            setEnabled(result.is_active);
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        //Swal.fire({
                        //    title: 'Erreur!',
                        //    text: "Une erreur s'est produite",
                        //    icon: 'error',
                        //    confirmButtonText: 'OK'
                        //});
                        setLoadingData(false);
                    });
            } catch (err) {
                //Swal.fire({
                //    title: 'Erreur!',
                //    text: "Une erreur s'est produite",
                //    icon: 'error',
                //    confirmButtonText: 'OK'
                //});
                setLoadingData(false);
            }
        }
    }

    const saveData = async (e) => {
        e.preventDefault();
        setLoadingData(true);
        try {
            if (id > 0) {
                axios.put(configData.API_SERVER + 'token/' + id ,
                    {
                        name: Name,
                        is_active: Enabled
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            Swal.fire({
                                title: 'Success !',
                                text: 'Successful Operation',
                                icon: 'success',
                                confirmButtonText: 'OK'
                            })
                            return history.push('/admin/tokens');
                        }
                    })
                    .catch(function (error) {

                    });
            }else{
                axios.post(configData.API_SERVER + 'token' ,
                    {
                        name: Name,
                        is_active: Enabled
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            Swal.fire({
                                title: 'Success !',
                                text: 'Successful Operation',
                                icon: 'success',
                                confirmButtonText: 'OK'
                            })
                            return history.push('/admin/tokens');
                        }
                    })
                    .catch(function (error) {

                    });
            }
        } catch (err) {

        }
    }

    useEffect(() => {
        getById();
        var selector = document.getElementById('title-section-id');
        if(selector != undefined){
            if(id > 0)
                selector.textContent = "Edit Token";
            else
                selector.textContent = "Create Token";
        }
    }, []);

    return (
        loadingData ?
            <div className="preload"><div className="preloader-spinner"></div></div>
            :
        <Form className="create-form" onSubmit={saveData} autoComplete="off">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="mb-3 col-md-6 col-sm-12">
                            <Form.Group className="form-group">
                                <Form.Label>Name</Form.Label>
                                <Form.Control name="Name" type="text" required placeholder='Name' value={Name} onChange={(e) => setName(e.target.value)} />
                            </Form.Group>
                        </div>
                        <div className="mb-3 col-md-6 col-sm-12">
                            <Form.Group className="form-group">
                                <Form.Label>Enabled</Form.Label>
                                <Form.Check name="Enabled" type="checkbox" checked={Enabled} onChange={(e) => setEnabled(!Enabled)} />
                            </Form.Group>
                        </div>

                        {/*<div className="mb-3 col-md-6 col-sm-12">*/}
                        {/*    <Form.Group className="form-group">*/}
                        {/*        <Form.Label>Type</Form.Label>*/}
                        {/*        <Form.Control name="Type" type="text" required placeholder='Type' value={Type} onChange={(e) => setType(e.target.value)} />*/}
                        {/*    </Form.Group>*/}
                        {/*</div>*/}
                        {/*<div className="mb-3 col-md-6 col-sm-12">*/}
                        {/*    <Form.Group className="form-group">*/}
                        {/*        <Form.Label>Group</Form.Label>*/}
                        {/*        <Form.Control name="Group" type="text" required placeholder='Group' value={Group} onChange={(e) => setGroup(e.target.value)} />*/}
                        {/*    </Form.Group>*/}
                        {/*</div>*/}
                        {/*<div className="mb-3 col-md-6 col-sm-12">*/}
                        {/*    <Form.Group className="form-group">*/}
                        {/*        <Form.Label>Schedule</Form.Label>*/}
                        {/*        <Form.Control name="Schedule" type="text" required placeholder='Schedule' value={Schedule} onChange={(e) => setSchedule(e.target.value)} />*/}
                        {/*    </Form.Group>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-12 footer-form">
                            <NavLink className="btn btn-secondary" to="/admin/tokens"><i className="fa fa-fw fa-times"></i> Cancel</NavLink>
                            <Button className="btn btn-primary" type='submit'><i className="fa fa-fw fa-floppy-o"></i> Save</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default TokenForm;
