import React, {useState} from 'react';
import "./login.css";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import logo from "../../assets/img/logo2.png"
import axios from 'axios';
import configData from '../../config';

const Login = () => {

    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");

    const userLogin = (e) => {
        e.preventDefault();
        try {
            axios
                .post(configData.API_SERVER + 'login', {
                    email: Email,
                    password: Password
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var user = response.data
                        window.localStorage.setItem('userLoggin', JSON.stringify(user));
                        window.location.reload(true)
                    }
                })
                .catch(function (error) {

                });
        } catch (err) {

        }
    }

    return (
        <div className="container">
            <div className="d-flex justify-content-center h-100">
                <div className="card">
                    <div className="card-header">
                        {/*<h3>Sign In</h3>*/}
                        <img className="logo-login" src={logo} />
                        {/*<div className="d-flex justify-content-end social_icon">*/}
                            {/*<span><i className="fab fa-facebook-square"></i></span>*/}
                            {/*<span><i className="fab fa-google-plus-square"></i></span>*/}
                            {/*<span><i className="fab fa-twitter-square"></i></span>*/}
                        {/*</div>*/}
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="input-group form-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fas fa-user"></i></span>
                                </div>
                                <input type="email" className="form-control" placeholder="email" value={Email} onChange={(e) => setEmail(e.target.value)} />

                            </div>
                            <div className="input-group form-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fas fa-key"></i></span>
                                </div>
                                <input type="password" className="form-control" placeholder="password" value={Password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            {/*<div className="row align-items-center remember">*/}
                                {/*<input type="checkbox" />Remember Me*/}
                            {/*</div>*/}
                            <div className="form-group">
                                <Nav.Item>
                                    <Nav.Link
                                        className="m-0 p-0"
                                        href="/"
                                        onClick={userLogin}              >
                                        <span className="btn login_btn">Login</span>
                                    </Nav.Link>
                                </Nav.Item>
                                {/*<input type="submit" value="Login" className="btn login_btn" />*/}
                            </div>
                        </form>
                    </div>
                    {/*<div className="card-footer">*/}
                        {/*<div className="d-flex justify-content-center links">*/}
                            {/*Don't have an account?<a href="#">Sign Up</a>*/}
                        {/*</div>*/}
                        {/*<div className="d-flex justify-content-center">*/}
                            {/*<a href="#">Forgot your password?</a>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
};

export default Login;
