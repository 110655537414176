import React, { useEffect, useState, Component } from 'react'
import { format } from 'fecha';
import {
    Form,
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import { NavLink, useHistory } from 'react-router-dom';
import axios from 'axios';
import configData from '../../config';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Icon from 'awesome-react-icons';
import ReactPaginate from "react-paginate";
import Swal from 'sweetalert2';

function DataGridList ({ columns = [], url = "", params = [], filters = [], options = {}, itemsPerPage = 10 }) {
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [paramsString, setParamsString] = useState("");
    const [filtersApplied, setfiltersApplied] = useState([]);
    const [filtersAppliedTitle, setfiltersAppliedTitle] = useState("");
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));

    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, settotalpages] = useState(0);
    const [pageSize, pageSizeSet] = useState(itemsPerPage);

    const history = useHistory();

    const appliedFilters = params;

    const OnDelete = async (id) => {
        Swal.fire({
            title: 'Confirmation',
            text: 'Are you sure?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.value) {
                DeleteItem(id);
            }
        })
    }

    const DeleteItem = async (id) => {
        setLoadingData(true);
        const requestOptions = {
            token: `${account.token}`,
            headers: { Authorization: `${account.token}` }
        };
        try {
            axios
                .delete(configData.API_SERVER + options['delete']['route'] + id, { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    console.log(response)
                    if (response.status == 200 || response.status == 204) {
                        Swal.fire({
                            title: 'Success !',
                            text: 'Successful Operation',
                            icon: 'success',
                            confirmButtonText: 'OK'
                        })
                        getData();

                    } else {
                        //Swal.fire({
                        //    title: 'Erreur!',
                        //    text: "Une erreur s'est produite",
                        //    icon: 'error',
                        //    confirmButtonText: 'OK'
                        //});
                        setLoadingData(false);
                    }
                })
                .catch(function (error) {
                    //Swal.fire({
                    //    title: 'Erreur!',
                    //    text: "Une erreur s'est produite",
                    //    icon: 'error',
                    //    confirmButtonText: 'OK'
                    //});
                    setLoadingData(false);

                });
        } catch (err) {
            //Swal.fire({
            //    title: 'Erreur!',
            //    text: "Une erreur s'est produite",
            //    icon: 'error',
            //    confirmButtonText: 'OK'
            //});
            setLoadingData(false);
        }
    }

    function handlePageClick({ selected: selectedPage }) {
        setLoadingData(true);
        setCurrentPage(selectedPage + 1);
        getData(pageSize, selectedPage + 1);
    }
    const OnChangeActiveValue = async (id, value, formdata = false) => {
        try {
            setLoadingData(true);
            axios.put(configData.API_SERVER + options['enabledOpt']['url_edit'] + id ,
                {
                    is_active: value
                },
                {
                    headers: {
                        "Authorization" : `Bearer ${account.token}`,
                        "Content-Type": formdata ? "multipart/form-data" : "application/json"
                    }
                })
                .then(function (response) {
                    if (response.status == 200 || 204) {
                        Swal.fire({
                            title: 'Success !',
                            text: 'Successful Operation',
                            icon: 'success',
                            confirmButtonText: 'OK'
                        })
                        getData();
                    }
                })
                .catch(function (error) {
                    getData();
                });
        } catch (err) {
            getData();
        }
    }

    const updateFilters = (event) => {
        setLoadingData(true);
        event.preventDefault();
        var filterDataApplied = [];
        setfiltersApplied([]);
        setfiltersAppliedTitle("");
        filters.map((item) => {
            let index = item.index
            if (event.target[index] != undefined) {
                item.value = (item.type == "checkbox") ? event.target[index].checked : event.target[index].value;

                if (item.value != undefined && item.value != "" && item.value != null) {
                    filterDataApplied.push({
                        index: item.index,
                        value: (item.type == "checkbox") ? String(item.value) : (item.type == "dropdown") ? item.list.find((e) => e.id === Number(item.value)).name : item.value
                    })
                }
            }
        });

        if (filterDataApplied.length > 0) {
            setfiltersApplied(filterDataApplied);
            setfiltersAppliedTitle("Filters Applied: ");
        }

        getData();
    }

    const getData = () => {
        setLoadingData(true);
        const paramsApply = params.concat(filtersApplied);
        let start = (currentPage - 1) * pageSize;
        let length = pageSize;
        try {
            // const requestOptions = {
            //     token: `${account.token}`,
            //     headers: { Authorization: `${account.token}` }
            // };
            axios.get(configData.API_SERVER + url ,
                { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data;
                        if (result) {
                            setData(result);
                            setTotalRecords(result.length);
                            // setcurrentRecords(result.length);
                            settotalpages(Math.ceil(result.length / pageSize));
                        }
                        // window.localStorage.setItem('userLoggin', JSON.stringify(user));
                        // window.location.reload(true)
                        // setData(result);
                        // setTotalRecords(result.length > 0 ? result[0].total : 0);
                        // settotalpages(Math.ceil(totalRecords / pageSize));
                        setLoadingData(false);
                    }
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    // const getListSize = () => {
    //     let mapaContainer = document.querySelector('.map-container-row');
    //     if(mapaContainer != null)
    //         setHeightMapa(mapaContainer.offsetWidth);
    // };

    useEffect(() => {
        getData();
    }, []);
    
    return (
        loadingData ?
            <div className="preload"><div className="preloader-spinner"></div></div>
        :
            <div>
                {filters.length > 0 ?
                    <div className="row all-list-filter-container">
                        {
                            filters.map((column, i) => {
                                let type = column.type
                                let index = column.index
                                let name = column.name
                                switch (type) {
                                    case "checkbox":
                                        return (
                                            <Form.Group className="col-md-3 col-sm-6 col-xs-12 list-filter-container" key={i}>
                                                <Form.Label>{name}</Form.Label>
                                                <Form.Check type="checkbox" name={index} onChange={(e) => updateFilters(e.target.checked, index)} />
                                            </Form.Group>
                                        );
                                    case "text":
                                        return (
                                            <Form.Group className="col-md-3 col-sm-6 col-xs-12 list-filter-container" key={i}>
                                                {/*<Form.Label>{name}</Form.Label>*/}
                                                <Form.Control className="list-filters" type="text" placeholder={name} name={index} onChange={(e) => updateFilters(e.target.value, index)} />
                                            </Form.Group>
                                        );
                                    case "number":
                                        return (
                                            <Form.Group className="col-md-3 col-sm-6 col-xs-12 list-filter-container" key={i}>
                                                {/*<Form.Label>{name}</Form.Label>*/}
                                                <Form.Control className="list-filters" type="number" placeholder={name} name={index} onChange={(e) => updateFilters(e.target.value, index)} />
                                            </Form.Group>
                                        );
                                    case "dropdown":
                                        return (
                                            <Form.Group className="col-md-3 col-sm-6 col-xs-12 list-filter-container custom-select" key={i}>
                                                {/*<Form.Label>{name}</Form.Label>*/}
                                                <Dropdown className="w-100">
                                                    <Dropdown.Toggle variant="success" className="w-100 dropdown-basic pl-20">
                                                        {(DivisionSelect == "" || DivisionSelect == null || DivisionSelect == undefined || DivisionSelect == "null") ? "Division" : DivisionSelect}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="w-100 pl-5">
                                                        {column.list.map((item, j) =>
                                                            <Dropdown.Item key={j} onClick={(e) => updateFilters(item.id, index, item.name)}>{item.name}</Dropdown.Item>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                {/*<Form.Select className="list-filters" name={index} onChange={(e) => updateFilters(e.target.value, index)}>*/}
                                                {/*    <option value="" className="d-none">{column['placeholder']}</option>*/}
                                                {/*    {column.list.map(item =>*/}
                                                {/*        <option className="p-10" value={item.id}>{item.name}</option>                                                    */}
                                                {/*    )}*/}
                                                {/*    {column.list.map(item =>*/}
                                                {/*        <option className="p-10" value={item.id}>{item.name}</option>*/}
                                                {/*    )}*/}
                                                {/*</Form.Select>*/}
                                            </Form.Group>
                                        );
                                    case "date":
                                        return (
                                            <Form.Group className="col-md-3 col-sm-6 col-xs-12 list-filter-container" key={i}>
                                                {/*<Form.Label>{name}</Form.Label>*/}
                                                <Form.Control className="list-filters" type="date" placeholder={name} name={index} onChange={(e) => updateFilters(e.target.value, index)} />
                                            </Form.Group>
                                        );
                                    case "timestamp":
                                        return (
                                            <Form.Group className="col-md-3 col-sm-6 col-xs-12 list-filter-container" key={i}>
                                                {/*<Form.Label>{name}</Form.Label>*/}
                                                <Form.Control className="list-filters" type="date" placeholder={name} name={index} onChange={(e) => updateFilters(!isNaN(new Date(e.target.value).getTime()) ? new Date(e.target.value).getTime() : null, index)} />
                                            </Form.Group>
                                        );
                                    default:
                                        return null;
                                }
                            })
                        }
                    </div>
                    :
                    null
                }
                <Card className="card-plain table-plain-bg">
                    <div className="tbar">
                        {(options['add']['route'] != "" && options['add']['route'] != undefined && options['add']['route'] != null) ?
                        <NavLink className="btn btn-primary btn-add"
                                 to={{
                                     pathname: options['add']['route']
                                 }}
                        ><i className="nc-icon nc-simple-add square bg-transparent font-weight-bold"></i> Add</NavLink>
                        : ""}
                    </div>
                    <Card.Body className="table-full-width table-responsive px-0">
                        <Table className="table-hover">
                            <thead>
                            <tr>
                                {columns.map((column, i) => (
                                    <th key={i} className="datagridHeader">
                                        {column.name}
                                    </th>
                                ))}
                                {
                                    (options['crud'] == true)
                                        ?
                                        <th className="datagridHeader options">Actions</th>
                                        :
                                        null
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.map((record, j) => (
                                    <tr>
                                        {columns.map((col, i) => {
                                            let type = col.type;
                                            var value = record[col.index];
                                            switch (type) {
                                                case "image":
                                                    var img = configData.BASE_PATH_BACKEND + value;
                                                    return (
                                                        <td key={i} className={record['disabledClass'] + " v-align-middle"}><img className="list-img" src={img} alt="user" height={50} width={50} /></td>
                                                    );
                                                case "date":
                                                    if (value != "" && value != undefined && value != null && value != 'null')
                                                        if (!isNaN(Date.parse(value))) {
                                                            var date = new Date(value);
                                                            date.setDate(date.getDate());
                                                            value = format(date, 'MM/DD/YYYY');
                                                            return (
                                                                <td key={i}>{value}</td>
                                                            );
                                                }
                                                case "timestamp":
                                                    if (value != "" && value != undefined && value != null && value != 'null') {
                                                        var date = new Date(value);
                                                        date.setDate(date.getDate());
                                                        value = format(date, 'MM/DD/YYYY');
                                                        return (
                                                            <td key={i}>{value}</td>
                                                        );
                                                }
                                                case "bool":
                                                    if (value)
                                                        value = 'True';
                                                    else
                                                        value = 'False';
                                                case "input-switch":
                                                    if(options['enabledOpt']['url_edit'] != "" && options['enabledOpt']['url_edit'] != undefined && options['enabledOpt']['url_edit'] != null){
                                                        if(options.hasOwnProperty('formdata'))
                                                            return (<td key={i}><BootstrapSwitchButton size="sm" checked={value} onChange={() => OnChangeActiveValue(record.id, !value, true)} /></td>);
                                                        else
                                                            return (<td key={i}><BootstrapSwitchButton size="sm" checked={value} onChange={() => OnChangeActiveValue(record.id, !value)} /></td>);
                                                    }
                                                    else{
                                                        if (value)
                                                            value = 'True';
                                                        else
                                                            value = 'False';
                                                    }
                                                default:
                                                    break;
                                            }

                                            return (
                                                <td key={i}>{value}</td>
                                            );
                                        })}
                                        {
                                            (options['crud'] == true)
                                                ?
                                                <td>
                                                    <div className="options-data" key={j}>
                                                        {
                                                            (options['details']['route'] != "" && options['details']['route'] != undefined && options['details']['route'] != null) ?
                                                                <NavLink className={(record[options['enabledOpt']['field']]) ? "crud-icons crud-icon-detail" : "crud-icons crud-icon-detail disabled"}
                                                                         to={{
                                                                             pathname: options['details']['route'],
                                                                             aboutProps: {
                                                                                 id: record.id
                                                                             }
                                                                         }}
                                                                ><Icon name="eye" /></NavLink>
                                                                :
                                                                ""
                                                        }

                                                        {
                                                            (options['edit']['route'] != "" && options['edit']['route'] != undefined && options['edit']['route'] != null) ?
                                                                <NavLink className={(record[options['enabledOpt']['field']]) ? "crud-icons crud-icon-edit" : "crud-icons crud-icon-edit disabled"}
                                                                         to={{
                                                                             pathname: options['edit']['route'],
                                                                             aboutProps: {
                                                                                 id: record.id
                                                                             }
                                                                         }}
                                                                ><Icon name="edit-pencil-simple" /></NavLink>
                                                                :
                                                                ""
                                                        }

                                                        {
                                                            (options['delete']['route'] != "" && options['delete']['route'] != undefined && options['delete']['route'] != null) ?
                                                                <a href="#" className={(record[options['enabledOpt']['field']]) ? "crud-icons crud-icon-delete" : "crud-icons crud-icon-delete disabled"} onClick={() => OnDelete(record.id)}>

                                                                    <Icon name="trash" />
                                                                </a>
                                                                :
                                                                ""
                                                        }
                                                    </div>
                                                </td>
                                                :
                                                null
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {/*<div className="table-pagination mt-25">*/}
                            {/*<ReactPaginate*/}
                                {/*previousLabel={"Previous"}*/}
                                {/*nextLabel={"Next"}*/}
                                {/*pageCount={totalPages}*/}
                                {/*onPageChange={handlePageClick}*/}
                                {/*marginPagesDisplayed={5}*/}
                                {/*pageRangeDisplayed={2}*/}
                                {/*containerClassName={"pagination"}*/}
                                {/*pageClassName={"page-item"}*/}
                                {/*pageLinkClassName={"page-link"}*/}
                                {/*previousLinkClassName={"page-link"}*/}
                                {/*previousClassName={"page-item"}*/}
                                {/*nextLinkClassName={"page-link"}*/}
                                {/*nextClassName={"page-item"}*/}
                                {/*breakLinkClassName={"page-link"}*/}
                                {/*breakClassName={"page-item"}*/}
                                {/*disabledClassName={"page-link-disabled"}*/}
                                {/*activeClassName={"page-link-active"}*/}
                            {/*/>*/}
                        {/*</div>*/}
                    </Card.Body>
                </Card>
            </div>
    );
}

export default DataGridList;