import React,{useEffect, useState, useRef} from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
// import React, {useEffect, useState} from "@types/react";
import {NavLink, useHistory} from "react-router-dom";
import axios from "axios";
import configData from "../../config";
import Swal from "sweetalert2";
import {Button, Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import Icon from 'awesome-react-icons';
import { format } from 'fecha';


const ScheduleCalendarForm = (props) => {
    const [EventData, setEventdata] = useState( {
        allFavs: [],
        favObj: {},
        start: '',
        end: '',
        filledIn: false,
        practiceTimes: []
    });
    const [title, setTitle] = useState("");
    const [Tokens, setTokens] = useState([]);
    const [Token, setToken] = useState(0);
    const [Contents, setContents] = useState([]);
    const [Content, setContent] = useState(0);
    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const [Enabled, setEnabled] = useState(true);
    const [loadingData, setLoadingData] = useState(false);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(0);
    const history = useHistory();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [events, setEvents] = useState([]);
    const start = new Date();
    const end = new Date(new Date().setMinutes(start.getMinutes() + 30));

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "schedule/" + id ,
                    { headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            var result = response.data;
                            setToken(result.token);
                            setContent(result.content);



                            var start_date = new Date(result.start_date);
                            setStartDate(start_date);

                            var end_date = new Date(result.end_date);
                            setEndDate(end_date);

                            setEnabled(result.is_active);
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        //Swal.fire({
                        //    title: 'Erreur!',
                        //    text: "Une erreur s'est produite",
                        //    icon: 'error',
                        //    confirmButtonText: 'OK'
                        //});
                        setLoadingData(false);
                    });
            } catch (err) {
                //Swal.fire({
                //    title: 'Erreur!',
                //    text: "Une erreur s'est produite",
                //    icon: 'error',
                //    confirmButtonText: 'OK'
                //});
                setLoadingData(false);
            }
        }
    }

    const saveData = async (e) => {
        e.preventDefault();
        setLoadingData(true);
        try {
            if (id > 0) {
                axios.put(configData.API_SERVER + 'schedule/' + id ,
                    {
                        token: Token > 0 ? Token : e.target.elements.Token.value,
                        content: Content > 0 ? Content : e.target.elements.Content.value,
                        start_date: StartDate,
                        end_date: EndDate,
                        is_active: Enabled
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            Swal.fire({
                                title: 'Success !',
                                text: 'Successful Operation',
                                icon: 'success',
                                confirmButtonText: 'OK'
                            })
                            getSchedules()
                            handleClose()

                        }
                    })
                    .catch(function (error) {

                    });
            }else{
                axios.post(configData.API_SERVER + 'schedule' ,
                    {
                        token: Token > 0 ? Token : e.target.elements.Token.value,
                        content: Content > 0 ? Content : e.target.elements.Content.value,
                        start_date: StartDate,
                        end_date: EndDate,
                        is_active: Enabled
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            Swal.fire({
                                title: 'Success !',
                                text: 'Successful Operation',
                                icon: 'success',
                                confirmButtonText: 'OK'
                            })
                            getSchedules()
                            handleClose()
                        }
                    })
                    .catch(function (error) {

                    });
            }
        } catch (err) {

        }
    }

    const getTokens = async () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'tokens' ,
                { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data;
                        setTokens(result);
                        setLoadingData(false);

                    }
                    if (Tokens.length)
                        setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    const getContents = async () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'contents' ,
                { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data;
                        setContents(result);
                        setLoadingData(false);

                    }
                    if (Contents.length)
                        setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    const getSchedules = () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'schedules' ,
                { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data;
                        var data = [];
                        for(var i = 0; i < result.length; ++i) {
                            data.push(
                                {
                                    title: result[i].content_name,
                                    start: result[i].start_date,
                                    end: result[i].end_date,
                                    extendedProps: {
                                        id: result[i].id,
                                        token: result[i].token,
                                        token_name: result[i].token_name,
                                        content: result[i].content,
                                        content_name: result[i].content_name,
                                        is_active: result[i].is_active,
                                        start_date: result[i].start_date,
                                        end_date: result[i].end_date,
                                    }
                                }
                            )
                        }
                        setLoadingData(false);
                        setEvents(data)

                    }
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    // const data = [
    //     {
    //         title: "sala 1",
    //         start,
    //         end,
    //         backgroundColor: "green",
    //         extendedProps: { id: 1 },
    //     },
    //     {
    //         title: "sala 2",
    //         start: new Date(new Date().setHours(start.getHours() + 1)),
    //         end: new Date(new Date().setHours(start.getHours() + 2)),
    //         backgroundColor: "purple",
    //         extendedProps: { id: 2 },
    //     },
    //     {
    //         title: "sala 3",
    //         start: new Date(new Date().setHours(start.getHours() + 2)),
    //         end: new Date(new Date().setHours(start.getHours() + 3)),
    //         backgroundColor: "#000",
    //         extendedProps: { id: 3 },
    //     },
    // ];

    const OnDelete = async () => {
        Swal.fire({
            title: 'Confirmation',
            text: 'Are you sure?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.value) {
                DeleteItem(id);
            }
        })
    }

    const DeleteItem = async (id) => {
        setLoadingData(true);
        const requestOptions = {
            token: `${account.token}`,
            headers: { Authorization: `${account.token}` }
        };
        try {
            axios
                .delete(configData.API_SERVER + "schedule/" + id, { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    console.log(response)
                    if (response.status == 200 || response.status == 204) {
                        Swal.fire({
                            title: 'Success !',
                            text: 'Successful Operation',
                            icon: 'success',
                            confirmButtonText: 'OK'
                        })
                        getSchedules();
                        handleClose();

                    } else {
                        //Swal.fire({
                        //    title: 'Erreur!',
                        //    text: "Une erreur s'est produite",
                        //    icon: 'error',
                        //    confirmButtonText: 'OK'
                        //});
                        setLoadingData(false);
                    }
                })
                .catch(function (error) {
                    //Swal.fire({
                    //    title: 'Erreur!',
                    //    text: "Une erreur s'est produite",
                    //    icon: 'error',
                    //    confirmButtonText: 'OK'
                    //});
                    setLoadingData(false);

                });
        } catch (err) {
            //Swal.fire({
            //    title: 'Erreur!',
            //    text: "Une erreur s'est produite",
            //    icon: 'error',
            //    confirmButtonText: 'OK'
            //});
            setLoadingData(false);
        }
    }

    const calendarRef = useRef(null);

    useEffect(() => {
        getSchedules();
        getTokens();
        getContents();
        getById();
        var selector = document.getElementById('title-section-id');
        if(selector != undefined){
            if(id > 0)
                setTitle("Edit Schedule");
            else
                setTitle("Create Schedule");
        }
    }, []);

    return (
        loadingData ?
            <div className="preload"><div className="preloader-spinner"></div></div>
            :
            // <div id='full-calendar'>
            //     <FullCalendar
            //         plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            //         initialView="dayGridMonth"
            //         select={handleTimeSelection(this)}
            //         eventBackgroundColor={'#FF4500'}
            //         headerToolbar={{
            //             left: 'prev,next',
            //             center: 'title',
            //             right: 'dayGridMonth,timeGridWeek,timeGridDay'
            //         }}
            //         editable={true}
            //         selectable={true}
            //         dayMaxEvents={true}
            //         aspectRadio={6}
            //         height={600}
            //         events={this.EventData.practiceTimes}
            //     />
            // </div>
            <div style={{ padding: 20 }}>
                <FullCalendar
                    nowIndicator={true}
                    editable={true}
                    eventResizableFromStart={false}
                    eventDurationEditable={false}
                    selectOverlap={false}
                    eventStartEditable={false}
                    views={{
                        dayGrid: {
                            selectable: true,
                        },
                        timeGrid: {
                            selectable: true,
                        },
                        dayGridMonth: {
                            selectable: false,
                        },
                    }}
                    ref={calendarRef}
                    plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                    initialView="timeGridWeek"
                    eventDrop={(info) => {
                        const eventFiltered = events.filter(
                            (event) => event.extendedProps.id !== info.event.extendedProps.id
                        );
                        setEvents([
                            ...eventFiltered,
                            {
                                title: info.event.title,
                                start: info.event.startStr,
                                end: info.event.endStr,
                                backgroundColor: info.event.backgroundColor,
                                extendedProps: { id: info.event.extendedProps.id },
                            },
                        ]);
                        alert("Dropped " + info.event.title);
                    }}
                    eventResize={(info) => {
                        const eventFiltered = events.filter(
                            (event) => event.extendedProps.id !== info.event.extendedProps.id
                        );
                        setEvents([
                            ...eventFiltered,
                            {
                                title: info.event.title,
                                start: info.event.startStr,
                                end: info.event.endStr,
                                backgroundColor: info.event.backgroundColor,
                                extendedProps: { id: info.event.extendedProps.id },
                            },
                        ]);
                        alert("Resized " + info.event.title);
                    }}
                    eventClick={(info) =>{
                        var start_date = new Date(info.event.extendedProps.start_date);
                        setStartDate(start_date);
                        var end_date = new Date(info.event.extendedProps.end_date);
                        setEndDate(end_date);
                        // setStartDate(info.event.extendedProps.start_date);
                        // setEndDate(info.event.extendedProps.end_date);
                        setId(info.event.extendedProps.id);
                        setToken(info.event.extendedProps.token);
                        setContent(info.event.extendedProps.content);
                        setEnabled(info.event.extendedProps.is_active);
                        handleShow();
                    }}
                    select={(info) => {
                        // setStartDate(info.startStr)
                        // setEndDate(info.endStr)
                        var start_date = new Date(info.startStr);
                        setStartDate(start_date);
                        var end_date = new Date(info.endStr);
                        setEndDate(end_date);
                        setId(0);
                        setToken(0);
                        setContent(0);
                        setEnabled(true);
                        handleShow();
                    }}
                    events={events}
                    // locale={"pt-br"}
                    // timeZone={"UTF"}
                    titleFormat={{ year: "numeric", month: "long" }}
                    // allDayText={"24h"}
                    allDaySlot={false}
                    headerToolbar={{
                        left: 'prev,next',
                        center: 'title',
                        // right: 'dayGridMonth,timeGridWeek,timeGridDay'
                        right: 'timeGridWeek,timeGridDay'
                    }}
                    aspectRadio={6}
                    height={600}
                />
                <Modal show={show} onHide={handleClose}>
                    <Form className="create-form" onSubmit={saveData} autoComplete="off">
                        <div className="card">
                            <div className="card-header">
                                {title}

                                {(id > 0) ?
                                    <a href="#" className="crud-icons crud-icon-delete delete-event-calendar"
                                       onClick={() => OnDelete()}>
                                        <Icon name="trash"/>
                                    </a>
                                    :
                                    ""
                                }
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="mb-3 col-12">
                                        <Form.Group className="form-group">
                                            <Form.Label>Start Date</Form.Label>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend calendar-icon">
                                                    <span className="input-group-text" ><i className="fa fa-fw fa-calendar"></i></span>
                                                </div>
                                                <div className="datapickerContainer">
                                                    <DatePicker
                                                        selected={StartDate}
                                                        className="form-control"
                                                        dateFormat="MM/dd/yyyy hh:mm"
                                                        readOnly={true}
                                                        minDate={new Date()}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="mb-3 col-12">
                                        <Form.Group className="form-group">
                                            <Form.Label>End Date</Form.Label>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend calendar-icon">
                                                    <span className="input-group-text" ><i className="fa fa-fw fa-calendar"></i></span>
                                                </div>
                                                <div className="datapickerContainer">
                                                    <DatePicker
                                                        selected={EndDate}
                                                        className="form-control"
                                                        dateFormat="MM/dd/yyyy hh:mm"
                                                        readOnly={true}
                                                        minDate={StartDate}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="mb-3 col-12">
                                        <Form.Group className="form-group">
                                            <Form.Label>Token</Form.Label>
                                            <select className="form-control" name="Token" value={Token} onChange={(e) => setToken(e.target.value)}>
                                                {Tokens.map((item, i) => (
                                                    <option key={i} value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </div>
                                    <div className="mb-3 col-12">
                                        <Form.Group className="form-group">
                                            <Form.Label>Content</Form.Label>
                                            <select className="form-control" name="Content" value={Content} onChange={(e) => setContent(e.target.value)}>
                                                {Contents.map((item, i) => (
                                                    <option key={i} value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </div>
                                    <div className="mb-3 col-12">
                                        <Form.Group className="form-group">
                                            <Form.Label>Enabled</Form.Label>
                                            <Form.Check name="Enabled" type="checkbox" checked={Enabled} onChange={(e) => setEnabled(!Enabled)} />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-12 footer-form">
                                        <Button className="btn btn-secondary" onClick={handleClose}><i className="fa fa-fw fa-times"></i> Cancel</Button>
                                        <Button className="btn btn-primary" type='submit'><i className="fa fa-fw fa-floppy-o"></i> Save</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal>
            </div>

    );
};

export default ScheduleCalendarForm;