import React, { useEffect, useState, SyntheticEvent } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {Form, Button} from 'react-bootstrap';
import configData from '../../config';
import axios from 'axios';
import Swal from 'sweetalert2';

const Details = (props) => {
    const [Name, setName] = useState('');
    const [Image, setImage] = useState('');
    const [Thumbnail, setThumbnail] = useState('');
    const [Url, setUrl] = useState('');
    const [Enabled, setEnabled] = useState(true);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(props?.location?.aboutProps?.id);
    const [loadingData, setLoadingData] = useState(false);
    const history = useHistory();
    const [PreviewImage, setPreviewImage] = useState();
    const [PreviewThumb, setPreviewThumb] = useState();

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "content/" + id ,
                    { headers: {
                        "Authorization" : `Bearer ${account.token}`
                    }
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            var result = response.data;
                            setName(result.name);
                            if (result.image != "" && result.image != undefined && result.image != null && result.image != 'null') {
                                setPreviewImage(configData.BASE_PATH_BACKEND + result.image);
                            }
                            if (result.thumbnail != "" && result.thumbnail != undefined && result.thumbnail != null && result.thumbnail != 'null') {
                                setPreviewThumb(configData.BASE_PATH_BACKEND + result.thumbnail);
                            }
                            setUrl(result.url);
                            setEnabled(result.is_active);
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        //Swal.fire({
                        //    title: 'Erreur!',
                        //    text: "Une erreur s'est produite",
                        //    icon: 'error',
                        //    confirmButtonText: 'OK'
                        //});
                        setLoadingData(false);
                    });
            } catch (err) {
                //Swal.fire({
                //    title: 'Erreur!',
                //    text: "Une erreur s'est produite",
                //    icon: 'error',
                //    confirmButtonText: 'OK'
                //});
                setLoadingData(false);
            }
        }else{
            return history.push('/admin/contents');
        }
    }

    useEffect(() => {
        getById();
        var selector = document.getElementById('title-section-id');
        if(selector != undefined)
            selector.textContent = "Details for Content with Id: " + id;
    }, []);

    return (
        loadingData ?
            <div className="preload"><div className="preloader-spinner"></div></div>
            :
            <div className="card">
                <div className="card-body">
                    <label>Name: </label>
                    <p className="card-text">{Name}</p>
                    <label>Image: </label>
                    <p className="card-text"><img className="max-width-300" src={PreviewImage} alt=""/></p>
                    <label>Thumbnail: </label>
                    <p className="card-text"><img className="max-width-300" src={PreviewThumb} alt=""/></p>
                    <label>Url: </label>
                    <p className="card-text">{Url}</p>
                    <label>Is Active: </label>
                    <p className="card-text">{Enabled ? "True" : "False"}</p>
                </div>
                <div className="card-footer">
                    <NavLink className="btn btn-secondary" to="/admin/contents"><i className="fa fa-chevron-left"></i> Back</NavLink>
                </div>
            </div>
    );
};

export default Details;
