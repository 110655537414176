import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import CloudPush from "views/CloudPush.js";
import Login from "views/login/index.js";
import Contents from "views/contents/index.js";
import ContentForm from "views/contents/form.js";
import ContentDetails from "views/contents/details.js";
import Schedules from "views/schedules/index.js";
import ScheduleForm from "views/schedules/form.js";
import ScheduleDetails from "views/schedules/details.js";
import Tokens from "views/tokens/index.js";
import TokenForm from "views/tokens/form.js";
import TokenDetails from "views/tokens/details.js";
import Statistics from "views/Statistics";

const account = JSON.parse(window.localStorage.getItem('userLoggin'));

const dashboardRoutes = [  
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "nc-icon fa fa-fw fa-bar-chart",
        component: Dashboard,
        layout: "/admin",
        // redirect: true
    },
    {
        path: "/tokens",
        name: (account != null && account.media_provider_type == 'BROADCAST') ?
            "Channels" :
            "Tokens",
        icon: "nc-icon nc-paper-2",
        component: Tokens,
        layout: "/admin"
    },
    {
        path: "/contents",
        name: "Content",
        icon: "nc-icon fa fa-fw fa-folder",
        component: Contents,
        layout: "/admin"
    },
    {
        path: "/schedules",
        name: "Schedule",
        icon: "nc-icon fa fa-calendar",
        component: Schedules,
        layout: "/admin"
    },
    {
        path: "/push",
        name: "Cloud push",
        icon: "nc-icon fa fa-bell",
        component: CloudPush,
        layout: "/admin"
    },
    {
        path: "/statistics",
        name: "Statistics",
        icon: "nc-icon fa fa-fw fa-line-chart",
        component: Statistics,
        layout: "/admin",
        // redirect: true
    },
    {
        path: "/brands",
        name: "Brands",
        icon: "nc-icon nc-bullet-list-67",
        component: TableList,
        layout: "/admin",
        // redirect: true
    },{
        path: "/billing",
        name: "Billing",
        icon: "nc-icon nc-money-coins",
        component: Icons,
        layout: "/admin",
        // redirect: true
    },
    {
        path: "/support",
        name: "Support",
        icon: "nc-icon fa fa-question-circle",
        component: Icons,
        layout: "/admin",
        // redirect: true
    },

    {
        path: "/user",
        name: "User Profile",
        icon: "nc-icon nc-circle-09",
        component: UserProfile,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/maps",
        name: "Maps",
        icon: "nc-icon nc-pin-3",
        component: Maps,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/notifications",
        name: "Notifications",
        icon: "nc-icon nc-bell-55",
        component: Notifications,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/token/form",
        name: null,
        component: TokenForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/token/details",
        name: null,
        component: TokenDetails,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/content/form",
        name: null,
        component: ContentForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/content/details",
        name: null,
        component: ContentDetails,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/schedule/form",
        name: null,
        component: ScheduleForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/schedule/details",
        name: null,
        component: ScheduleDetails,
        layout: "/admin",
        redirect: true
    },
];

export default dashboardRoutes;
